.login {
  background: $lightBeige;
  padding: 30px 0;
  
  @include mq(md) {
    padding: 50px 0;
  }

  .row {
    align-items: stretch;
  }

  &__box {
    padding: 30px;
    border: 1px solid $darkBeige;
    height: 100%;
    margin-bottom: 30px;

    @include mq(md) {
      padding: 50px;
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $titleFont;
    color: $darkGray;
    letter-spacing: 1.5px;
    font-weight: 300;
    margin: 0 0 30px;
    position: relative;

    &:after {
      content: '';
      width: 80px;
      height: 1px;
      background: $darkBeige;
      position: absolute;
      bottom: -15px;
      display: block;
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: 20px;
    font-weight: 400;
    color: $midGray;
  }

  &__form {
      width: 100%;

      @include mq(md) {
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
      }
  }

  &__form-group {
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      &.--actions {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;

        button,
        a {
          width: 47% !important;
          margin: 0;
        }
      }

      &.--captcha {
        display: flex;
        align-items: center;
      }
  }

  &__form-input {
      border: 1px solid $lightGray;
      width: 100%;
      height: 100%;
      background: $white;
      font-size: 20px;
      color: $darkGray;
      padding-left: 10px;
      outline: none;
      z-index: 1;
      position: relative;
      height: 50px;
      font-weight: 400;

      &:focus {
          padding-top: 20px;

          & + label {
              top: 12px;
              font-size: 14px;
              opacity: 1;
          }
      }

      &::placeholder {
          opacity: 0;
      }

      &:not(:placeholder-shown) {
          padding-top: 20px;

          + label {
              opacity: 1;
              top: 12px;
              font-size: 14px;
          }
      }


      &--textarea {
          height: 150px;

          &:focus {
              padding-top: 25px;
          }

          &:not(:placeholder-shown) {
              padding-top: 25px;
          }
      }

      &--checkbox {
        position: absolute;
        left: -9999px;

        & + label {
          padding-left: 28px;
          position: relative;
          top: 0 !important;
          transform: none;
          pointer-events: all;
          cursor: pointer;
          left: 0;
          opacity: 1;

          &:before {
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            border: 1px solid $lightGray;
            background: $white;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-radius: 2px;
          }

          &:after {
            @include trans;
            content: '';
            position: absolute;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $darkBeige;
            border-left: 2px solid $darkBeige;
            transform: translateY(-50%) rotate(-45deg);
            top: calc(50% - 1px);
            left: 5px;
            opacity: 0;
          }
        }

        &:checked + label {
          &:after {
            opacity: 1;
          }
        }
      }
  }

  &__form-label {
      position: absolute;
      top: 50%;
      left: 10px;
      font-size: 16px;
      transform: translateY(-50%);
      color: $darkGray;
      z-index: 2;
      transition: all .5s ease;
      pointer-events: none;
      margin: 0;
      font-weight: 400;
      opacity: .5;

      &--textarea {
          top: 25px
      }
  }

  &__form-button {
      @include iconedButton('fill', $darkBeige, $white);
      padding-left: 40px;
      padding-right: 40px;
      margin-right: 0;

      &.--left {
        margin-right: 0;
        text-decoration: none;
        width: auto;
        display: inline-block;
      }

      &.--outline {
        border:1px solid $darkBeige;
        background: transparent;
        color: $darkBeige;
        text-decoration: none;
      }
  }

  &__form-button-captcha {
    background: transparent;
    width: 32px;
    height: 32px;
    border: 1px solid $darkBeige;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;

    img {
      width: 16px;
    }
  }
}