.quantity-selector {
    border: 1px solid $lightGray;
    height: 50px;
    display: flex;
    margin-right: 10px;
    display: inline-flex;

    .-button,
    .-qty {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        color: $darkGray;

        i {
            color: $darkGray;
        }
    }

    .-button {
        cursor: pointer;
        outline: none;
    }

    .-qty {
        width: 30px;
    }
}