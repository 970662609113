.cuenta {
  position: relative;
  padding: 0 0 50px 0;

  @include mq(md) {
    padding: 50px 0;
  }

  &__menu {
    background: $lightBeige;
    padding: 15px;
    margin-bottom: 40px;

    @include mq(md) {
      margin-bottom: 0;
    }

    li {
      position: relative;
      padding: 0 0 15px;

      &.-active {
        a {
          color: $darkBeige;
          pointer-events: none;
        }

        &:after {
          content: '';
          position: absolute;
          width:10px;
          height: 10px;
          border-top: 2px solid $darkBeige;
          border-right: 2px solid $darkBeige;
          transform: translateY(-50%) rotate(45deg);
          right: 20px;
          top:25%;
        }
      }
    }

    a {
      color: $darkGray;
      font-size: 14px;
      text-decoration: none;
      font-weight: 400;
    }
  }

  &__info {

  }

  &__info-title {
    font-family: $titleFont;
    color: $darkGray;
    letter-spacing: 1.5px;
    font-weight: 300;
    margin: 0 0 30px;
    position: relative;

    &:after {
      content: '';
      width: 80px;
      height: 1px;
      background: $darkBeige;
      position: absolute;
      bottom: -15px;
      display: block;
    }
  }

  &__info-section {
    padding-top: 40px;

    .product {
      margin-bottom: 40px;
      height: auto;

      @include mq(md) {
        margin-bottom: 0;
        height: 100%;
      }
    }

    &__row {
      @include mq(md) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__col {
      margin-bottom: 40px;

      @include mq(md) {
        width: 46%;
        margin-bottom: 0;
      }

      &.--buttons {
        display: flex;
        flex-wrap: wrap;

        @include mq(md) {
          width: 100%;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      color: $midGray;
      margin: 0 0 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid $midGray;
      font-weight: 400;
    }

    &__item {
      color: $darkBeige;
      font-weight: 400;
      margin: 0 0 15px;
      text-transform: uppercase;
    }

    &__text {
      font-weight: 400;
      margin: 0 0 5px;
      display: block;
      color: $midGray;
    }

    &__button {
      @include iconedButton('fill', $darkBeige, $white);
      padding-left: 40px;
      padding-right: 40px;
      margin-right: 0;
      border:1px solid $darkBeige;
      background: transparent;
      color: $darkBeige;
      text-decoration: none;
      display: inline-flex;
      margin-top: 10px;
      font-weight: 400;
      width: 100%;
      
      @include mq(md) {
        width: auto;
      }
    }
  }

  .--menucol {
    &:before {
      content: '';
      position: absolute;
      right: -15px;
      top:-30vh;
      bottom:-30vh;
      width: 50vw;
      background: $lightBeige;
      display: none;
      z-index: 0;
  
      @include mq(md) {
        display: block;
      }
    }
  }
}

