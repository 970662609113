.product-detail {
    overflow: hidden;
    &__container {
    }

    &__product-content {
        margin-bottom: 50px;

        @include mq(md) {
            display: flex;
        }
    }

    &__product-slider-container { 
      position: relative;
      width: 100%;
      background: $lightBeige;

      @include mq(md) {
        width: 50%;
      }
    }

    &__product-slider {
        width: 100%;

        @include mq(md) {
            width: 100%;
        }

        .slick-dots {
          bottom: 10px;

          li {
            button {
              color: $white;
              &:before {
                font-size: 8px;
                color: $white;
                opacity: 1;
              }
            }
          }
    
          .slick-active {
            button {
              &:before {
                color: $darkBeige;
                font-size: 8px;
              }
            }
          }

          @include mq(md) {
            display: none !important;

          }
        }

        .slick-next,
        .slick-prev {
            background: transparent;
            box-shadow: none;

            &:before {
                width: 30px;
                height: 30px;
            }
        }

        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: 20px;
        }
    }

    &__product-slide { 
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            width: auto;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -40%, 0);
            height: 60%;

            @include mq(md) {
              transform: translate3d(-50%, -50%, 0);
              height: 70%;
            }
        }
    }

    &__product-breadcrumb {
        position: absolute;
        top: 40px;
        left: 50%;
        color: $darkGray;
        text-transform: uppercase;
        transform: translate3d(-50%, 0, 0);
        width: 100%;
        text-align: center;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        z-index: 10;
  
        @include mq(md) {
          padding: 0;
          width: auto;
          text-align: left;
          left: 60px;
          font-size: 12px;
          transform: translate3d(0, 0, 0);
        }

        a {
          text-decoration: none;
          color: $darkGray;
        }
    }

    &__product-description {
      padding: 0 15px;

      @include mq(md) {
        padding: 80px;
        width: 50%;
      }

        &__title {
            color: $darkGray;
            text-transform: uppercase;
            font-weight: 100;
            font-size: 24px;
            max-width: 70%;
            margin: 0;
            margin-bottom: 20px;
            font-weight: 400;

            @include mq(md) {
              font-size: 32px;
            }
        }

        &__qty {
            font-size: 16px;
            color: $darkGray;
            margin: 0;
            margin-bottom: 20px;
            display: block;

            @include mq(md) {
              font-size: 18px;
            }
        }
    }

    &__product-price-wrapper {
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;
    }

    &__product-price {
        color: $darkBeige;
        font-size: 24px;

        &:last-child {
            color: $darkGray;
            font-size: 16px;
            text-decoration-line: line-through;
            margin-left: 20px;

            @include mq(md) {
              font-size: 20px;
            }
        }

        @include mq(md) {
          font-size: 22px;
        }
    }

    &__product-rate {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        & > div > div {
            &:hover {
                .-js-star-filled {
                    opacity: 1;
                }            
    
                & ~ div {
                    .-js-star-filled {
                        opacity: 1;
                    }            
                }
            }
        }
    }

    &__product-stars {
        display: flex;
        align-items: center;
        direction: rtl;
    }


    &__product-star-wrapper {
        position: relative;
        margin-right: 3px;

        i {
            color: $darkBeige;
        }

        .-js-star-filled {
            @include trans;

            opacity: 0;
            position: absolute;
            left: 0;
        }
    }

    &__product-controls {
        
        margin-bottom: 40px;

        @include mq(md) {
          display: flex;
        }
    }

    &__product-favorite {
        height: 50px;
        display: flex;
        margin-top: 20px;
        
        @include mq(md) {
          margin-top: 0;
        }
    }

    &__add-to-cart {
        @include iconedButton('fill', $darkBeige, $white);
        @include trans;

        text-transform: uppercase;
        margin-right: 3px;
        margin-left: 0;
        padding: 0 40px;

        @include mq(md) {
          margin-right: 3px;
        }

        &:hover {
            background: $darkBeige - #111;
        }

        span {
            margin-right: 20px;
            letter-spacing: 5px;
        }
    }

    &__favorite {
        @include trans;
        background: $darkBeige;
        width: 50px;
        border: none;
        cursor: pointer;

        i {
          color: $white;
          @include trans;
        }

        &:hover {
            i {
                color: $red;
            }
        }
    }

    &__product-description-text {
        max-width: 100%;
        margin: 0;
        margin-bottom: 40px;
        color: $darkGray;

        @include mq(md) {
          max-width: 80%;
        }
    }

    &__social {
        display: flex;
    }

    &__item {
        margin-right: 20px;

        a {
            text-decoration: none;
            display: flex;
            align-items: flex-end;
            height: 22px;
        }

        i {
            color: $darkGray;
            font-size: 20px;
            height: 20px;
        }

        &--inverted {
          a {
            align-items: flex-start;
          }

          i{
            font-size: 20px;
            border-radius: 50%;
            color: $white;
            // background: $darkGray;
            position: relative;

            &:after {
              position: absolute;
              top: calc(50% + 1px);
              left: calc(50%);
              transform: translate3d(-50%, -50%, 0);
              content: '';
              width: 10px;
              height: 18px;
              border-radius: 50%;
              background: $darkGray;
              z-index: -1;
            }
          }          
        }
    }

    &__product-info {
        &__wrapper {
            max-width: 900px;
            margin-right: auto;
            margin-left: auto;
        }

        &__tabs {
            display: flex;
            position: relative;
            justify-content: center;

            @include mq(md) {
              justify-content: flex-start;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -50px;
                right: -50px;
                height: 1px;
                background: $lightGray;

            }
        }
        
        &__tab {
            display: block;
            padding: 10px 5px 10px 5px;
            font-size: 16px;
            margin-right: 10px;
            text-transform: uppercase;
            color: $darkGray;
            position: relative;
            cursor: pointer;
            font-weight: 400;

            &:after {
                @include trans;

                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 1px;
                background: $darkBeige;
                z-index: 2;
                opacity: 0;
            }

            &.-active,
            &:hover {
                &:after {
                    opacity: 1;
                    width: 100%;
                }
            }

            @include mq(md) {
              padding: 20px 0 10px 0;
              font-size: 30px;
              margin-right: 80px;
            }
        }

        &__content-wrapper {
            position: relative;
            min-height: 700px;

            @include mq(md) {
              min-height: 400px;
            }
        }

        &__content {
            @include trans;
            position: absolute;
            top: 0;
            left:0;
            padding: 30px 20px;
            opacity: 0;
            
            &.-active {
              opacity: 1;
            }
            
            @include mq(md) {
              padding: 60px 0;
              display: flex;
              flex-wrap: wrap;
            }
        }

        &__group {
            width: 100%;
            display: flex;
            margin-bottom: 40px;

            @include mq(md) {
              width: 50%;
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
            margin-right: 15px;

            @include mq(md) {
              width: 50px;
              height: 50px;
              margin-right: 0;
            }

            img {
              max-width: 100%;
              max-height: 100%;

              @include mq(md) {
                width: auto;
                height: auto;
              }
            }
        }

        &__title {
            font-size: 24px;
            margin: 0;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $darkGray;
            font-weight: 400;

            @include mq(md) {
              font-size: 20px;
            }
        } 

        &__text {
            font-size: 18px;
            margin: 0;
            color: $darkGray;
            font-weight: 400;
            @include mq(md) {
              font-size: 16px;
            }
        }
    }
}