.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: $white;
  border-bottom: 1px solid $lightGray;
  // height: 50px;

  // @include mq(md) {
  //   height: 120px;
  // }

  // &__container {
  //   width: 100%;
  // }

  &__top-row {
    @include trans;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 30px;
    left:30px;
    z-index: 10000;
    opacity: 0;
    transform: translateX(-100%);
    
    @include mq(md) {
      height: 40px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      position: static;
      transform: translateX(0);
      opacity: 1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      right: -100%;
      height: 1px;
      background: $lightGray;
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  &__support-mail {
    margin-bottom: 10px;
    
    @include mq(md) {
      margin-bottom: 0;
    }

    &__text {
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 14px;
      display: block;
      font-weight: 500;
      color: $darkestGray;

      @include mq(md) {
        display: inline;
        margin-bottom: 0;
      }
    }

    i {
      font-size: 16px;
      color: $darkBeige;
    }

    &__address {
      font-size: 14px;
      margin-left: 10px;
      text-decoration: none;
      color: $darkBeige;
    }
  }


  &__alt-nav {
    &__list {
      
      @include mq(md) {
        display: flex;
      }
    }

    &__item {
      margin-bottom: 10px;

      @include mq(md) {
        margin-left: 20px;
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
        font-size: 14px;
        color: $darkestGray;
        font-weight: 500;
      }
    }
  }

  &__bottom-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: relative;

    @include mq(md) {
      height: 80px;
    }
  }

  &__searchbar {
    @include trans;

    position: absolute;
    top: calc(100% + 1px);
    height: 0;
    left: 0;
    display: flex;
    border-bottom-left-radius : 5px;
    border-bottom-right-radius : 5px;
    border-left: 1px solid $lightGray;
    border-right: 1px solid $lightGray;
    border-bottom: 1px solid $lightGray;
    z-index: 999;
    background: $white;
    width: 100%;
    pointer-events: none;
    overflow: hidden;
    opacity: 0;
    padding-left: 10px;

    @include mq(md) {
      left: 0;
      right: auto;
      width: 25%;
    }

    input {
      @include trans;
      opacity: 0;
      width: 100%;
      border: none;
      border-radius: 5px;
      outline: none;
      color: $darkestGray;
    }

    &.-open {
      height: 50px;
      pointer-events: all;
      opacity: 1;

      input {
        opacity: 1;
      }
    }

    &__button {
      @include trans;
      
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 50px;
      text-decoration: none;
      
      @include mq(md) {
      }

      img {
        width: 20px;
      }
    }
  }


  &__wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    
    @include mq(md) {
      width: 100%;
    }
  }

  &__search-icons {
    width: 20px;
    position: relative;
    cursor: pointer;

    @include mq(md) {
    }

    i, img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: $darkestGray;
      font-size: 20px;
    }

    .-js-search {
      @include trans;
      width: 20px;
    }

    .-js-close {
      @include trans;
      opacity: 0;
    }

    &.-open {
      .-js-search {
        opacity: 0;
      }

      .-js-close {
        @include trans;
        opacity: 1;
      }
    }
  }

  &__logo-mobile {
    height: 100%;
    display: flex;
    align-items: center;

    @include mq(md) {
      display: none;
    }

    img {
      height: 80%;
    }
  }

  &__logo {
    height: 60%;
    width: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    
    @include mq(md) {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 60%;
    }

    img {
      height: 100%;
    }
  }

  &__main-nav {
    @include trans;

    position: fixed;
    top: 50px;
    left: 0;
    bottom:0;
    width: 100%;
    background: $white;
    transform: translateX(-110%);
    z-index: 1000;
    padding: 30px;
    
    @include mq(md) {
      display: flex;
      padding: 0;
      background: transparent;
      position: relative;
      width: 90%;
      height: 100%;
      transform: translateX(0);
      flex-grow: 2;
      justify-content: space-evenly;
      align-items: center;
      top: 0;
      height: 100%;
    }

    &__list {
      
      @include mq(md) {
        display: flex;
        justify-content: center;
        width: 40%;
      }

      &:first-of-type {
        justify-content: flex-end;
      }

      &:last-of-type {
        justify-content: flex-start;
      }
    }

    &__item {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      @include mq(md) {
        margin-bottom: 0;
        margin: 0 20px;
      }
      
      
      a {
        @include trans;
        text-decoration: none;
        font-family: $mainFont;
        text-transform: uppercase;
        color: $darkestGray;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 1.1px;

        @include mq(md) {
          font-size: 14px;
        }

        &:hover {
          color: $darkBeige;
        }
      }
    }
  }

  &__cart {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      height: 12px;
      width: 12px;
      position: absolute;
      right: -5px;
      bottom: -5px;
      border-radius: 50%;
      border: 1px solid $white;
      background: $darkBeige;
    }

    @include mq(md) {
      margin-left: 0;
    }

    img {
      width: 100%;
    }
  }

  &__shop-cart {
    @include trans;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.40);
    z-index: 1002;
    opacity: 0;
    pointer-events: none;

    &.-open { 
      z-index: 1002;
      opacity: 1;
      pointer-events: all;

      .header__shop-cart__container {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &__container {
      @include trans;
      padding: 20px;
      position: absolute; 
      top: 0;
      right: 0;
      height: 100vh;
      width: 90%;
      background: $white;
      z-index: 1002;
      transform: translateX(100%);
      opacity: 0;
      overflow-y: auto;

      @include mq(md) {
        width: 50%;
        padding: 80px;
      }
    }

    &__close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 40px;
      width: 40px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transform: rotate(45deg);

      @include mq(md) {
        top: 40px;
        right: 40px;
        height: 40px;
        width: 40px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 30px;
        height: 2px;
        background: $darkestGray;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        height: 30px;
        width: 2px;
        background: $darkestGray;
      }
    }
    
    &__title {
      margin: 0;
      margin-bottom: 20px;
      font-size: 21px;
      font-weight: 100;
      text-transform: uppercase;
      font-family: $titleFont;
      color: $darkestGray;

      @include mq(md) {
        font-size: 40px;
      }
    }

    &__heading {
      padding: 20px 40px 20px 0;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;
    }

    &__heading-title {
      font-size: 16px;
      color: $darkestGray;
      text-transform: uppercase;

      @include mq(md) {
        font-size: 24px;
      }
    }

    &__accordion {
      @include trans;
      
      &.-closed {
          height: 0 !important;
          opacity: 0 !important;
          overflow: hidden !important;

          .checkout__accordion-item {
              opacity: 0;
              overflow: hidden;
              padding: 0;
          }

          .cart-product {
              opacity: 0;
              overflow: hidden;
              padding: 0;
          }
      }
    }

    &__accordion-item {
        @include trans;

        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid $lightGray;
    }

    &__subtotals {
        padding: 20px 0;
        border-bottom: 1px solid $lightGray;
    }

    &__subtotal,
    &__envio,
    &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &__text,
        &__number {
            font-size: 14px;
            color: $darkestGray;

            @include mq(md) {
              font-size: 18px;
            }
        }

        &__text {
            text-transform: uppercase;
        }

        &__number {
            font-size: 14px;

            @include mq(md) {
              font-size: 16px;
            }
        }
      }

      &__subtotal {
          margin-bottom: 20px;
      }

      &__total {
          padding: 20px 0;

          &__text,
          &__number {
              font-size: 20px;
              color: $darkestGray;
          }

          &__number {
              color: $darkBeige;
          }
      }

    &__button {
        @include iconedButton('fill', $darkBeige, $white);
        margin-bottom: 20px;
        text-decoration: none;
        height: 50px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2px;


        &:hover {
          color: $white;
        }
    }
  }

  $h: &;

  &__hamburguer {
    cursor: pointer;
    width: 25px;
    height: auto;
    z-index: 1001;
    margin-left: 10px;

    @include mq(md) {
      display: none;
    }

    span {
      background-color: $mainColor;
      border-radius: 2px;
      content: '';
      display: block;
      width: 100%;
      height: 2px;

      &:nth-child(1) {
        animation: outT 0.6s $easing backwards;
        animation-direction: reverse;
      }

      &:nth-child(2) {
        margin: 7px 0;
        animation: outM 0.6s $easing backwards;
        animation-direction: reverse;
      }

      &:nth-child(3) {
        animation: outBtm 0.6s $easing backwards;
        animation-direction: reverse;
      }
    }
  }

  &.-open {
    #{$h}__hamburguer {
      span:nth-child(1) {
        animation: inT 0.6s $easing forwards;
      }

      span:nth-child(2) {
        animation: inM 0.6s $easing forwards;
      }

      span:nth-child(3) {
        animation: inBtm 0.6s $easing forwards;
      }
    }

    #{$h}__main-nav {
      transform: translateX(0);
    }

    #{$h}__top-row {
      display: block;
      transform: translateX(0);
      opacity: 1;
    }
  }
}

//hamburguer animations
@keyframes inM {
    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(9px) rotate(0deg);
    }

    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(9px) rotate(0deg);
    }

    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-9px) rotate(0deg);
    }

    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-9px) rotate(0deg);
    }

    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}
