.catalogo {
    padding-bottom: 100px;

    &.-top-banner {
        .catalogo__top-banner {
            display: block;
            width: 100%;
            position: relative;
            height: 200px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 20px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,.20);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &__title {
                color: $white;
                font-family: $titleFont;
                font-size: 40px;
                font-weight: 100;
                margin: 0;
                margin-bottom: 20px;
                text-transform: uppercase;
                position: relative;
                z-index: 2;
            }

            &__text {
                color: $white;
                font-size: 20px;
                position: relative;
                z-index: 2;
            }
        }
    }

    &__top-banner {
        display: none;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $lightGray;
      
      i {
        color: $darkBeige;

        @include mq(md) {
          display: none;
        }
      }
    }

    &__filters {
      @include trans;

      background: $white;
      z-index: 100;
      position: fixed;
      left:0;
      width: 90%;
      top:50px;
      bottom: 0;
      transform: translateX(-100%);
      padding-top: 30px;

      &.-open {
        transform: translateX(0);
        box-shadow: 10px 0 10px rgba(0,0,0,.15);
        overflow: auto;
      }

      @include mq(md) {
        padding-top: 0;
        top: 0;
        position: relative;
        width: 25%;
        transform: translateX(0);
      }

      .js-catalogoFilterClose {
        @include trans;
        background: $darkBeige;
        padding: 10px 13px;
        color: $white;
        position: absolute;
        right:0;
        border: none;
        top: 0;

        &:focus {
          outline: none;
        }

        @include mq(md) {
          display: none;
        }
      }
    }

    &__title {
        font-family: $titleFont;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 100;
        color: $darkGray;
        margin: 0;
        padding: 20px 0;
        position: relative;

        @include mq(md) {
          font-size: 40px;
        }
    }

    &__filter-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 3px);
            right: 15px;
            height: 10px;
            width: 10px;
            border-left: 1px solid $darkGray;
            border-bottom: 1px solid $darkGray;
            transform: translateY(-50%) rotate(-45deg);
            pointer-events: none;
        }
    }

    &__filter-text {
        color: $darkGray;
        font-size: 14px;
        margin-right: 10px;

        @include mq(md) {
          margin-right: 20px;
          font-size: 16px;
        }
    }

    &__filter-input {
        height: 40px;
        border: 1px solid $lightGray;
        color: $darkGray;
        width: 100px;
        padding: 0 10px;
        -webkit-appearance: none;
        cursor: pointer;
        font-family: $mainFont;
        font-size: 14px;

        @include mq(md) {
          width: 150px;
          font-size: 16px;
        }
    }

    &__container {
        width: 100%;
    }

    &__group {
        padding: 20px 0;
        width: 100%;
        border-bottom: 1px solid $lightGray; 
    }

    &__group-content {
    }

    &__active-filter {
        display: inline-flex;
        align-items: center;
        background: $lightBeige;
        padding: 10px 15px;
        border-radius: 3px;
        margin-bottom: 5px;

        i {
            color: $darkGray;
            cursor: pointer;
        }
        
        &__text {
            color: $darkGray;
            margin-right: 20px;
        }
    }

    &__group-title {
        font-family: $titleFont;
        font-size: 22px;
        font-weight: 100;
        margin: 0;
        margin-bottom: 20px;
        color: $darkGray;
        text-transform: uppercase;
    }

    &__group-list {
        width: 100%;
    }

    &__group-item  {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between;
        color: $darkGray;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__filters-group {

    }

    &__filters-input {
        position: absolute;
        left: -9999px;
        // -webkit-appearance: none;

        &:checked + label {
            &:after {
                opacity: 1;
            }
        }
    }

    &__filters-label {
        padding-left: 30px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            height: 15px;
            width: 15px;
            border: 1px solid $lightGray;
            background: $lightBeige;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-radius: 3px;
        }

        &:after {
            @include trans;
            content: '';
            position: absolute;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $darkBeige;
            border-left: 2px solid $darkBeige;
            transform: translateY(-50%) rotate(-45deg);
            top: calc(50% - 1px);
            left: 3px;
            opacity: 0;
        }
    }

    &__price-range {
        position: relative;
        width: 100%;

        .ghost,
        .original {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;

            &::-webkit-slider-runnable-track {
                height: 3px;
            }
        }

        &__bar-wrapper {
            width: 100%;
            position: relative;
            height: 30px;
        }

        &__labels-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                display: block;
                color: $darkGray;
            }
       }

       &__labels-group {
           display: flex;
           align-items: center;

           .-currency {
               color: $darkGray;
               display: block;
               margin-right: 10px;
           }
       }

       &__label {
            border: 1px solid $midGray;
            padding: 10px;
            font-size: 14px;
            display: block;
            text-align: center;
            color: $darkGray;
        }
    }

    &__product-container {
        margin-top: 20px;
    }

    &__product-container {
        padding-bottom: 30px;
        height: 100%;
    }
}