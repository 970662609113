.footer {
  position: relative;
  z-index: 2;
  
  &__disclaimer {
    background: $white;
    padding: 15px 0;
    
    p {
      width: auto;
      margin: 0;
      flex-shrink: 2;
    }
  }
  
  &__disclaimer-content {
    text-align: center;
    font-weight: 400;
    color: $midGray;

    @include mq(md) {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }

    &__legal {
      flex-shrink: 2;
      width: auto;
      a {
        display: block;
        text-decoration: none;
        color: $midGray;

        @include mq(md) {
         margin-left: 15px;
        }
      }
      @include mq(md) {
        display: flex;
      }
    }
  }

  &__top-row {
    background: $darkBeige;
    padding: 20px 0;

    @include mq(md) {
      padding: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;

    @include mq(md) {
      padding: 40px 0;
    }
  }

  &__icon {
    width: 40px;
    margin-right: 20px;

    @include mq(md) {
      width: 60px;
      margin-right: 40px;
    }

    img {
      width: 100%;
    }
  }

  &__content {
    width: 55%;

    &__title {
      margin: 0;
      margin-bottom: 10px;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 1.5px;

      @include mq(md) {
        font-size: 20px;
      }
    }

    &__text {
      margin: 0;
      color: $white;
    }
  }

  &__bottom-row {
    background: $corpBlack;
    padding-top: 40px;

    @include mq(md) {
      padding: 80px 0;
    }
  }

  &__wrapper {
    margin-bottom: 40px;
    
    @include mq(md) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }

  &__newsletter {
    &__title {
      font-size: 16px;
      color: $lightGray;
      margin: 0;
      margin-bottom: 20px;
    }

    &__text {
      color: $lightGray;
    }

    &__input-group {
      border: 1px solid $midGray;
      height: 50px;
      display: flex;
      position: relative;

      i {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $midGray;
      }
    }

    &__input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      outline: none;
      caret-color: $midGray;
      padding-left: 20px;
      color: $midGray;
    }

    &__submit {
      position: absolute;
      width: 50px;
      height: 50px;
      background: transparent;
      right:0;
      top:0;
      border:none;
      cursor: pointer;

      &:after,
      &:before {
        display: block;
        content: '';
        border-top: 1px solid $midGray;
        position: absolute;
        right: 15px;
        top:50%;
      }
      
      &:after {
        border-right: 1px solid $midGray;
        height: 15px;
        width: 15px;
        transform: translate3d(0, -50%, 0) rotate(45deg);
      }
      
      &:before {
        width: 25px;
        transform: translate3d(0, -50%, 0);
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__social{
    &__title {
      font-size: 16px;
      color: $lightGray;
    }

    &__list {
      display: flex;
    }

    &__item {
      margin-right: 20px;

      i {
        color: $lightGray;
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include mq(md) {
      margin-bottom: 0;
    }

    &--top {
      margin-bottom: 40px;

      @include mq(md) {
        height: 120px;
      }
    }

    &__title {
      color: $lightGray;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 20px;
    }

    &__mail,
    &__phone,
    &__text,
    &__item {
      color: $lightGray;
      text-decoration: none;
      margin: 0;
      margin-bottom: 10px;
      
      a {
        text-decoration: none;
        color: $lightGray;
        margin-bottom: 10px;
      }
    }

    &__logos {
      display: flex;
      flex-wrap: wrap;
    }

    &__logo-wrapper  {
      width: 100%;
    }

    &__wrapper {
      display: flex;
      margin-bottom: 10px;
      align-items: flex-start;

      i,
      img {
        width: 20px;
        flex-shrink: 0;
        color: $lightGray;
        margin-right: 10px;
      }
    }
  }
}
