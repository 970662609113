.edad {
  background: #000 url('/images/age.jpg') no-repeat center center / cover;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 10000;
  width: 100%;
  margin-top: 0!important;

  &__box {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate3d(-50%,-50%,0);
    color: #fff;
    text-align: center;
    width: 100%;

    @include mq(md) {
      top: 50%;
      width: auto;
    }
  }

  &__box-text {
    text-transform: uppercase;
    margin-bottom: 40px;

    h1 {
      font-weight: 500;
      margin: 0 0 10px;
      line-height: 1;
      font-size: 24px;

      @include mq(md) {
        font-size: 36px;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;

      @include mq(md) {
        font-size: 16px;
      }
    }
  }

  &__box-logo{
    margin-bottom: 40px;
  }

  &__box-actions {
    display: flex;
    justify-content: center;

    a {
      width: 40%;
      border: 1px solid #fff;
      color: #fff;
      text-decoration: none;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 10px;

      &:hover {
        color: $mainColor;
        border: 1px solid $mainColor;
      }
    }
  }

  &__disclaimer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 40px;
    color: $midGray;
  }

  &__disclaimer-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mq(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__disclaimer-copy {
    font-size: 12px;
    max-width: 300px;
    width: 90%;
    text-align: center;
    margin-bottom: 15px; 
    font-weight: 400;
    
    @include mq(md) {
      margin-bottom: 0;
      margin-right: 40px;
    }
  }

  &__disclaimer-credits {
    text-transform: uppercase;
    font-size: 12px;
    max-width: 150px;
    text-align: center;
    margin-bottom: 15px; 
    font-weight: 400;     

    @include mq(md) {
      text-align: left;
      margin-bottom: 0;
    }
  }
  &__disclaimer-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin: 0 5px;
    }
  }
}