.terms-conditions {
    background: $lightBeige;
    padding: 40px 0;

    @include mq(md) {
        padding: 80px 0;
    }

    &__title {
        font-family: $titleFont;
        font-size: 30px;
        color: $darkGray;
        margin: 0;
        margin-bottom: 20px;
        font-weight: 100;
        text-transform: uppercase;
    }

    &__group {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
    }

    &__group-title {
        color: $darkGray;
        margin: 0;
        font-weight: 100;
        margin-bottom: 10px;
    }

    &__text {
        color: $darkGray;
        margin: 0;
        margin-bottom: 20px;

        a {
            color: $darkBeige
        }
    }

    &__list {
        list-style: decimal;
        list-style-position: outside;
        padding-left: 40px;
    }

    &__item {
        color: $darkGray;
        margin-bottom: 20px;

            a {
            color: $darkBeige
        }
    }

}