// Import Modules
@import 'mixins';
@import 'libraries';
@import 'modules';
@import 'components';
@import 'pages';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
    font-family: $mainFont;
    font-weight: 300;
    overflow-x: hidden;
    width: 100vw;
    font-size: $sm;
}

main {
  margin-top: 50px;

  @include mq(md) {
    margin-top: 120px;
  }
}

a {
    color:$link;
    transition: all $duration $easingLight;

    &:hover{
        color:$linkHover;
        text-decoration: none;
    }
}

button,
input,
textarea,
a {
  font-family: $mainFont;
}

ul {
    font-family: $mainFont;
    list-style: none;
    padding: 0;
    margin: 0;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
