@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$fa-font-display: block !default;

@font-face {
  font-family: 'Font Awesome 5 Regular';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('/fonts/fa-regular-400.eot');
  src: url('/fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('/fonts/fa-regular-400.woff') format('woff'),
  url('/fonts/fa-regular-400.ttf') format('truetype'),
  url('/fonts/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Regular';
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Solid';
    font-style: normal;
    font-weight: 900;
    font-display: $fa-font-display;
    src: url('/fonts/fa-solid-900.eot');
    src: url('/fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fa-solid-900.woff') format('woff'),
    url('/fonts/fa-solid-900.ttf') format('truetype'),
    url('/fonts/fa-solid-900.svg#fontawesome') format('svg');
}
  
.fa,
.fas {
    font-family: 'Font Awesome 5 Solid';
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: $fa-font-display;
    src: url('/fonts/fa-brands-400.eot');
    src: url('/fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fa-brands-400.woff') format('woff'),
    url('/fonts/fa-brands-400.ttf') format('truetype'),
    url('/fonts/fa-brands-400.svg#fontawesome') format('svg');
}
  
.fab {
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-ExtraBold.woff2') format('woff2'),
      url('/fonts/FuturaStd-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-Bold.woff2') format('woff2'),
      url('/fonts/FuturaStd-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-BookOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-BookOblique.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-Book.woff2') format('woff2'),
      url('/fonts/FuturaStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-BoldOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-BoldOblique.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-Light.woff2') format('woff2'),
      url('/fonts/FuturaStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-Heavy.woff2') format('woff2'),
      url('/fonts/FuturaStd-Heavy.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-ExtraBoldOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-ExtraBoldOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-LightOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-Medium.woff2') format('woff2'),
      url('/fonts/FuturaStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-HeavyOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-HeavyOblique.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/FuturaStd-MediumOblique.woff2') format('woff2'),
      url('/fonts/FuturaStd-MediumOblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Haigrast Serif';
  src: url('/fonts/HaigrastSerif-Regular.woff2') format('woff2'),
      url('/fonts/HaigrastSerif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haigrast';
  src: url('/fonts/haigrast-Regular.woff2') format('woff2'),
      url('/fonts/haigrast-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

