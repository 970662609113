.contacto {
    background: $lightBeige;
    display: flex;
    flex-wrap: wrap;

    &__container {
        width: 100%;
        padding: 40px 20px;

        @include mq(md) {
            width: 60%;
            padding: 80px;
        }
    }

    &__heading {
        width: 100%;

        &__title {
            font-family: $altFont;
            transform: rotate(-5deg);
            color: $darkBeige;
            font-weight: 100;
            text-align: center;
            font-size: 40px;
            margin: 0;
            padding-bottom: 10px;
            position: relative;
            margin-bottom: 20px;

            @include mq(md) {
                margin-bottom: 40px;
                font-size: 80px;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) rotate(5deg);
                width: 100px;
                height: 1px;
                background: $darkBeige;
            }
        }

        &__text {
            width: 100%;
            margin-bottom: 40px;
            text-align: center;
            color: $darkGray;

            @include mq(md) {
                max-width: 400px;
                margin-right: auto;
                margin-left: auto;
                font-size: 20px;
            }
        }
    }

    &__form {
        width: 100%;

        @include mq(md) {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__form-group {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
    }

    &__form-input {
        border: 1px solid $lightGray;
        width: 100%;
        height: 100%;
        background: $white;
        font-size: 20px;
        color: $darkGray;
        padding-left: 10px;
        outline: none;
        z-index: 1;
        position: relative;
        height: 50px;
    
        &:focus {
            padding-top: 20px;
    
            & + label {
                top: 12px;
                font-size: 14px;
    
            }
        }
    
        &::placeholder {
            opacity: 0;
        }
    
        &:not(:placeholder-shown) {
            padding-top: 20px;
    
            + label {
                opacity: 1;
                top: 12px;
                font-size: 14px;
            }
        }


        &--textarea {
            height: 150px;

            &:focus {
                padding-top: 25px;
            }

            &:not(:placeholder-shown) {
                padding-top: 25px;
            }
        }
    }

    &__form-label {
        position: absolute;
        top: 50%;
        left: 10px;
        font-size: 16px;
        transform: translateY(-50%);
        color: $darkGray;
        z-index: 2;
        transition: all .5s ease;
        pointer-events: none;
        margin: 0;

        &--textarea {
            top: 25px
        }
    }

    &__form-button {
        @include iconedButton('fill', $darkBeige, $white);
        padding-left: 40px;
        padding-right: 40px;
    }

    &__image {
        overflow: hidden;
        
        @include mq(md) {
            width: 40%;
        }

        img {
            height: 100%;
        }
    }
}