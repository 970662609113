.about {
    background: $lightBeige;
    overflow: hidden;

    &__image-top {
        width: 100%;
        margin-bottom: 40px;
        height: 100vw;
        overflow: hidden;
        position: relative;

        @include mq(md) {
            margin-bottom: 80px;
            height: auto;
        }

        img {
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @include mq(md) {
                position: static;
                left: 0;
                transform: translateX(0);
                height: auto;
                width: 100%;
            }
        }
    }

    &__title {
        font-family: $altFont;
        transform: rotate(-5deg);
        color: $darkBeige;
        font-weight: 100;
        text-align: center;
        font-size: 40px;
        margin: 0;
        position: relative;
        margin-bottom: 20px;
        padding: 0 15px 10px 15px;

        @include mq(md) {
            margin-bottom: 40px;
            font-size: 80px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) rotate(5deg);
            width: 100px;
            height: 1px;
            background: $darkBeige;
        }
    }

    &__subtitle {
        font-family: $titleFont;
        text-transform: uppercase;
        color: $darkGray;
        font-size: 20px;
        text-align: center;
        width: 100%;
        padding: 0 15px;
        margin-bottom: 40px;

        @include mq(md) {
            font-size: 24px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__images {
        width: 100%;
        margin-bottom: 40px;

        @include mq(md) {
            display: flex;
            justify-content: space-between;
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 80px;
        }
    }

    &__image {
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        @include mq(md) {
            width: 48%;
            padding-bottom: 48%;
        }

        &:last-child {
            img {
                @include mq(md) {
                    margin-top: 50px;
                }
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;

            @include mq(md) {
                width: 100%;
                height: auto;
            }
        }
    }

    &__text {
        color: $darkGray;
        width: 100%;
        padding: 0 15px;
        margin: 0;
        margin-bottom: 40px;

        @include mq(md) {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 80px;
            font-size: 20px;
        }
    }

    &__video {
        width: 100%;
        height: 0;
        padding-bottom: 56.5%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }
        
    }
    
    &__video-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50% ,0);
        text-decoration: none;

        i {
            font-size: 80px;
            color: $white;
        }

    }

    &__image-bottom {
        width: 100%;
        margin-bottom: -4px;
        height: 100vw;
        overflow: hidden;
        position: relative;

        @include mq(md) {
            height: auto;
        }

        img {
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @include mq(md) {
                position: static;
                left: 0;
                transform: translateX(0);
                height: auto;
                width: 100%;
            }
        }
    }
}