@mixin trans($prop: all, $d: $duration, $e: $easing) {
    transition: $prop $d $e;
}

@mixin title($size: $lg, $color: $white, $align: 'left') {
    text-transform: uppercase;
    font-size: $size;
    color: $color;
    text-align: #{$align};
}

@mixin padd($mode: both, $size: full) {
    @if $size == full {

        @if $mode == both {

            padding-top: 16%;
            padding-bottom: 16%;

            @include mq(sm){
                padding-top: 12%;
                padding-bottom: 12%;
            }

            @include mq(md){
                padding-top: 8%;
                padding-bottom: 8%;
            }
        }

        @if $mode == top {

            padding-top: 16%;

            @include mq(sm){
                padding-top: 12%;
            }

            @include mq(md){
                padding-top: 8%;
            }
        }

        @if $mode == bottom {

            padding-bottom: 16%;

            @include mq(sm){
                padding-bottom: 12%;
            }

            @include mq(md){
                padding-bottom: 8%;
            }
        }
    }

    @if $size == half {
        @if $mode == both {

            padding-top: 8%;
            padding-bottom: 8%;

            @include mq(sm){
                padding-top: 6%;
                padding-bottom: 6%;
            }

            @include mq(md){
                padding-top: 4%;
                padding-bottom: 4%;
            }
        }

        @if $mode == top {

            padding-top: 8%;

            @include mq(sm){
                padding-top: 6%;
            }

            @include mq(md){
                padding-top: 4%;
            }
        }

        @if $mode == bottom {

            padding-bottom: 8%;

            @include mq(sm){
                padding-bottom: 6%;
            }

            @include mq(md){
                padding-bottom: 4%;
            }
        }
    }
}

@mixin overlay($level: .5, $zdex: 2) {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,$level);
    z-index: $zdex;
}

@mixin close($color: $white, $size: 24px) {
    position: absolute;
    display: block;
    width: $size;
    height: $size;
    cursor: pointer;
    transform: rotate(45deg);

    span{
        width: 100%;
        height: 2px;
        position: absolute;
        top:calc(50% - 1px);
        background: $color;
        display: block;

        &:last-child {
            transform: rotate(90deg);
        }
    }
}

@mixin caret($point: up, $size: 20px, $color: $white) {
    width: $size;
    height: $size;
    position: relative;
    display: inline-block;

    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-bottom: 2px solid $color;
        border-left: 2px solid $color;
        left:0;

        @if $point == up {
            top:60%;
            transform: rotate(135deg);
        }

        @if $point == down {
            bottom:10%;
            transform: rotate(-45deg);
        }
    }
}

@mixin iconedButton($type: 'fill', $color: $white, $contrast: $darkestGray) {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    color: $color;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    
    @include mq(md) {
        
    }

    span {
        letter-spacing: 4px;
        font-size: 12px;
    }

    img {
        width: 16px;
    }

    @if $type == 'fill' {
        @include trans;

        background: $color;
        border: 1px solid $color;
        color: $contrast;

        &:hover {
            background: $color - #111;
            border: 1px solid $color - #111;
        }
    }

    @if $type == 'outline' {
        @include trans;

        background: transparent;
        border: 1px solid $color;
        color: $color;

        span {
            @include trans;

            color: $color;
            font-size: 12px;
        }

        &:hover {
            background: $color + #666;
            border: 1px solid $color + #666;

            span {
                color: $color;
            }
        }
    }
}