.cart-product {
    @include trans;
    
    width: 100%;
    display: flex;
    // max-height: 160px;

    @include mq(md) {
        max-height: 160px;
    }
    
    &__image-wrapper {
        width: 30%;

        @include mq(md) {
            width: 20%;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        position: relative;

        @include mq(md) {
            max-height: 160px;
        }

        img {
            position: absolute;
            top: 0;
            height: 100%;
            left: 0;

            @include mq(md) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__content {
        display: block;
        width: 40%;

        @include mq(md) {
            width: 60%;
        }

        span {
            display: block;
        }
    }

    &__title {
        font-size: 16px;

        @include mq(md) {
            font-size: 24px;
        }
    }

    &__title,
    &__qty {
        margin: 0;
        margin-bottom: 5px;
        color: $darkGray;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 18px;
    }

    &__price {
        color: $darkBeige;
        margin-bottom: 10px;
    }

    &__qty,
    &__price {
        font-size: 14px;
        font-weight: 400;


        @include mq(md) {
            font-size: 16px;
        }
    }

    &__total {
        width: 30%;
        display: flex;
        justify-content: flex-end;

        @include mq(md) {
            width: 20%;
        }

        i {
            color: $darkGray;
            margin-left: 10px;

            @include mq(md) {
                margin-left: 20px;
            }
        }

        &__price {
            color: $darkGray;
            font-weight: 400;
        }
    }
}